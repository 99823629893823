<template>
    <ul class="index_item">
        <li v-for="(item, index) in showData" :key="index" @click="routerGo(item)">
            <a target="_blank">
                <img :src="item.aimage" :alt="item.title"/>
                <p>{{ item.title }}</p>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        showData: {
            default: ()=> []
        }
    },
    data() {
        return {};
    },
    methods: {
        routerGo({ id }) { // 跳转功能页页面
            if(id) {
                this.$axios.post("/api/yunshi/common/detail", { id }).then((res) => {
                    if(res.code == 1) {
                        let _data = res.data;
                        document.title = _data.title || "运势"; // 设置页面标题
                        let routerName = _data.detail_template_filename;
                        if(routerName) this.goDetail(routerName, id);
                    };

                }).catch((err) => {
                    console.log(err);
                }).finally(()=> {

                });
            };
        },
        goDetail(routerName, id) {
			this.$router.push({
				name: routerName,
				query: {
                    themefunction_id: id,
                    // bd_vid: 'sds' // test
                }
			});
		}
    }
};
</script>

<style lang="less" scoped>
.index_item {
	overflow: hidden;
	padding: 14px 2px 5px;
	background-color: #fff
}

.index_item li {
	width: 25%;
	float: left;
	text-align: center;
	margin-bottom: 10px;
	overflow: hidden
}

.index_item a {
	display: block;
	margin: 0 12%;
	overflow: hidden
}

.index_item img {
	display: block;
	margin: 0 auto 5px;
	width: 100%;
	height: auto;
	border-radius: 30px
}
</style>