<template>
	<div class="public_footer_servers" :style="wrapperStyle">
		<div class="public_footer_help" :style="aStyle" v-if="hideText != true && footerData != null">
			<div v-if="footerData.company">
				<span class="words">{{ footerData.company }}</span>
			</div>
			<div v-if="footerData.mobile">
				<span class="words">{{ footerData.mobile }}  {{ footerData.address }}</span>
			</div>
			<div v-if="footerData.icp">
				<span class="words">{{ footerData.icp }}</span>
			</div>
			<!-- <span class="words">如需帮助</span>
			<a :style="aStyle" href="http://wpa.qq.com/msgrd?v=3&uin=<{$lianxifs}>&site=qq&menu=yes" target="blank" class="kf">请联系专属售后客服</a> -->
		</div>
		<img src="https://szwh.oss-cn-chengdu.aliyuncs.com/assets/imgs/common/img_foot_xin.png?x-oss-process=style/webp" alt="诚信、可信网站" class="public_foot_xin" />
		<img src="https://szwh.oss-cn-chengdu.aliyuncs.com/assets/imgs/common/img_foot_al.png?x-oss-process=style/webp" alt="阿里云提供数据安全保护" class="public_foot_al" />
	</div>
</template>

<script>
export default {
	props: {
		bg: {}, // 文字颜色
		bgColor: {}, // 背景色
		hideText: {
			default: false
		}
	},
	data() {
		return {
		    footerData: null
		}
	},
	mounted() {
		this.getFooterData();
	},
	methods: {
		getFooterData() {
			this.$axios.post("/api/index/company").then((res) => {
				if(res.code == 1) {
					let _data = res.data || [];
					let url = location.href;

					_data.forEach((item) => {
						if(url.indexOf(item.url) != -1) {
							this.footerData = item;
						};
					});
				};
			}).catch((err) => {
				console.log(err);
			});
		}
	},
	computed: {
		aStyle() {
			return {
				color: this.bg ? this.bg : '#6c6c6c',
			}
		},
		wrapperStyle() {
			return {
				color: this.bg ? this.bg : '#6c6c6c',
				background: this.bg ? this.bgColor : '#6c6c6c'
			}
		}
	}
}
</script>

<style lang="less" scoped>
.public_footer_help,
.public_footer_help .kf {
	color: #e6dbc6;
}

.public_footer_servers {
	text-align: center;
	color: #6c6c6c;
	padding: .2rem 0;
	font-size: .14rem
}

.public_footer_servers>p {
	padding: 1px 0
}

.public_footer_servers a {
	// color: #6c6c6c
}

.public_footer_help {
	overflow: hidden;
	line-height: 1.5
}

.public_footer_help>img {
	width: .2rem;
	height: .2rem;
	margin-right: .05rem;
	margin-left: .05rem;
	display: inline-block;
	vertical-align: middle
}

.public_footer_help .words {
	display: inline-block
}

.public_footer_help .kf {
	position: relative;
	display: inline-block;
	text-decoration: underline;
	padding-left: 5px
}

.public_footer_help .kf::before {
	content: "";
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
	height: 20px;
	background-size: 100%
}

.public_foot_xin {
	width: 100%;
	margin: 0 auto;
	display: block
}

.public_foot_al {
	width: 50%;
	margin: 0 auto;
	display: block
}
</style>