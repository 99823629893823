<template>
    <footer class="public_footer">
        <!--<div class="public_footer_help">
            <span class="words">如需帮助</span>
            <a href="http://wpa.qq.com/msgrd?v=3&uin=<{$lianxifs}>&site=qq&menu=yes" target="blank" class="kf">请联系专属售后客服</a>
        </div>-->
        <p>
            <a href="http://beian.miit.gov.cn/" rel="nofollow" target="_blank">
                皖ICP备2024048770号-1
            </a><br>
            圣智文化
        </p>
        <img src="https://szwh.oss-cn-chengdu.aliyuncs.com/assets/imgs/common/img_foot_xin.png?x-oss-process=style/webp" alt="诚信、可信网站" class="public_foot_xin" />
        <img src="https://szwh.oss-cn-chengdu.aliyuncs.com/assets/imgs/common/img_foot_al.png?x-oss-process=style/webp" alt="阿里云提供数据安全保护" class="public_foot_al" />
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.public_footer {
	margin: 20px 0 0;
	background-color: #dc8732;
	overflow: hidden;
	width: 100%;
	text-align: center;
	color: #fff;
	padding: 20px 0
}

.public_footer>p {
	padding: 2px 0
}

.public_footer a {
	color: #fff
}

.pf_links {
	padding: 10px 0
}

.pf_payment {
	position: relative;
	text-align: center;
	margin-top: 8px
}

.pf_payment img,.pf_payment span {
	height: 24px;
	vertical-align: middle;
	display: inline-block;
	padding: 0 4px;
	line-height: 24px;
	width: auto
}

.public_footer_servers {
	text-align: center;
	color: #6c6c6c;
	padding: .2rem 0;
	font-size: .14rem
}

.public_footer_servers>p {
	padding: 1px 0
}

.public_footer_servers a {
	color: #6c6c6c
}

.public_footer_help {
	overflow: hidden;
	line-height: 1.5
}

.public_footer_help>img {
	width: .2rem;
	height: .2rem;
	margin-right: .05rem;
	margin-left: .05rem;
	display: inline-block;
	vertical-align: middle
}

.public_footer_help .words {
	display: inline-block
}

.public_footer_help .kf {
	position: relative;
	display: inline-block;
	text-decoration: underline;
	padding-left: 5px
}

.public_footer_help .kf::before {
	content: "";
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);height: 20px;
	background-size: 100%
}

.public_foot_xin {
	width: 100%;
	margin: 0 auto;
	display: block
}

.public_foot_al {
	width: 50%;
	margin: 0 auto;
	display: block
}
</style>