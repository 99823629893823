import Vue from 'vue'

import MustKnow from '@/components/must-know/index.vue';
import Roulette from '@/components/roulette/index.vue';
import Home from '@/views/Home/home.vue';
import OrderQuery from '@/components/order-query/index.vue';
import PayModal from '@/components/pay-model/index.vue';

Vue.component('MustKnow', MustKnow);
Vue.component('Roulette', Roulette);
Vue.component('Home', Home);
Vue.component('OrderQuery', OrderQuery);
Vue.component('PayModal', PayModal);