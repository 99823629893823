import QRCode from 'qrcodejs2';

export default {
	beforeCreate() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	},
    computed: {
        isMobile() {
            const userAgent = navigator.userAgent.toLowerCase();
            const mobileKeywords = ['iphone', 'android', 'windows phone', 'ipad', 'ipod'];
            
            return mobileKeywords.some(keyword => userAgent.includes(keyword));
        }
    },
    data() {
        return {
            timer: null, // 待支付页面定时器
			showQrCode: false,
			orderData: {}, // 待支付页面数据
			isFirstTag: false,
			isShowRoulette: false
        };
    },
    methods: {
		// 后续数据获取改为路由守卫中获取 

        setRouletteState(state) { // 设置 loading 轮盘显示状态
            this.isShowRoulette = state;
        },

        // ------------------------------------------ 表单提交逻辑 ------------------------------------------ //
        
        submitForm(url, formData) { // 提交表单
			formData.domain = location.origin;// 当前域名

            this.$axios.post(url, formData).then((res) => {
				if(res.code == 1) {
					let _data = res.data;
					document.title = '待支付订单'; // 设置页面标题
					let oid = _data.oid;
					this.getOrderStateOnce(oid);
				}else {
                    layer.msg(res.msg || res.message);
					this.$emit("setRouletteState", false);
                };;

			}).catch((err) => {
				this.$emit("setRouletteState", false);
				console.log(err);
			});
        },
		getOrderStateOnce(oid) { // 获取当前支付状态
			this.$axios.post("/api/yunshi/common/searchDetail", { oid }).then((res) => {
				if(res.code == 1) {
					let _data = res.data;
					let routerName = _data.status == "0" ? _data.payingpage : '_PAID_';
					if(routerName == "_PAID_") { // 已支付
						let phone_page = this.$store.state.template && this.$store.state.template.phone_template_filename;
						if(!phone_page) { // localstorage 中取
                            let template = localStorage.getItem('template');
                            if(template) phone_page = JSON.parse(phone_page).phone_template_filename;
                        };
						if(phone_page) {
							this.goUnpaidDetail_phone(phone_page, oid);
						}else {
							this.getPhonePage(oid);
						};
					}else if(routerName){
						this.goUnpaidDetail(routerName, oid);
					};
				};
			}).catch((err) => {
				console.log(err);
			}).finally(()=> {
                this.$emit("setRouletteState", false);
            });
		},
		getPhonePage(oid) { // set themeid && set template
			this.$axios.post("/api/yunshi/common/theme").then((res)=> {
				if(res.code == 1) {
					let _data = res.data;
					let themeid = _data.id;
					this.setThemeid(themeid);
					this.setTemplate({
						qywx_template_filename: _data.qywx_template_filename || '',
						phone_template_filename: _data.phone_template_filename || '',
						paydoc_template_filename: _data.paydoc_template_filename || '',
						unpaydoc_template_filename: _data.unpaydoc_template_filename || ''
					});

					let phone_page = _data.phone_template_filename || 'submitPhoneNum';
					if(phone_page) this.goUnpaidDetail_phone(phone_page, oid);
				};
			}).catch((err)=> {
				console.log(err);
			});
		},
		setTemplate(template) { // set template
			this.$store.commit("setTemplate", template);
		},
		setThemeid(themeid) { // set themeid
			this.$store.commit("setThemeid", themeid);
		},
		goUnpaidDetail_phone(routerName, oid) { // 号码填写界面
			let query = {
				oid
			};
			let bd_vid = this.$route.query.bd_vid;
			if(bd_vid) query.bd_vid = bd_vid;

			let requestid = this.$route.query.requestid;
			if(requestid) query.requestid = requestid;

			let adid = this.$route.query.adid;
			if(adid) query.adid = adid;

			this.$router.push({
				name: routerName,
				query
			});
		},
		goUnpaidDetail(routerName, oid) { // 跳转待支付界面
			let query = {
				oid
			};

			let bd_vid = this.$route.query.bd_vid;
			if(bd_vid) query.bd_vid = bd_vid;

			let requestid = this.$route.query.requestid;
			if(requestid) query.requestid = requestid;

			let adid = this.$route.query.adid;
			if(adid) query.adid = adid;

			let themefunction_id = this.$route.query.themefunction_id;
			if(themefunction_id) query.themefunction_id = themefunction_id;

			let filename  = this.$route.name;
			if(filename ) query.filename  = filename ;

			this.$router.push({
				name: routerName,
				query
			});
		},

        // ------------------------------------------ 轮询查看状态 ------------------------------------------ //

        getUnpaidOrder(oid) { // 轮询获取当前支付状态
            this.$axios.post("/api/yunshi/common/searchDetail", { oid }).then((res) => {
                if(res.code == 1) {
                    let _data = res.data;
                    if(!this.isFirstTag) { // 存储数据
						this.orderData = _data;
                        this.detailData = _data.detaildata;
                        this.resultPage = _data.orderpage;
						this.isFirstTag = true;
                    };
    
                    if(_data.status == "0") {
                        this.timer = setTimeout(() => {
                            clearTimeout(this.timer);
                            this.getUnpaidOrder(oid);
                        }, 1000);
                    }else {
                        let phone_page = this.$store.state.template && this.$store.state.template.phone_template_filename;
                        if(!phone_page) { // localstorage 中取
                            let template = localStorage.getItem('template');
                            if(template) phone_page = JSON.parse(phone_page).phone_template_filename;
                        };
                        if(phone_page) {
                            this.goUnpaidDetail_phone(phone_page, oid);
                        }else {
                            this.getPhonePage(oid);
                        };
                    };
                };
            }).catch((err) => {
                console.log(err);
            }).finally(()=> {
                this.$emit("setRouletteState", false);
            });
        },

		// ------------------------------------------ 获取支付链接 ------------------------------------------ //

		getPayUrl(payWay, payType) { // H5 调起支付 / PC 获取二维码生成地址
			let oid = this.$route.query.oid;

			let phone_page = this.$store.state.template && this.$store.state.template.phone_template_filename;
			if(!phone_page) { // localstorage 中取
				let template = localStorage.getItem('template');
				if(template) phone_page = JSON.parse(phone_page).phone_template_filename;
			};

			if(phone_page) {
				this.getPayUrl$0(payWay, payType, phone_page, oid);
			}else {
				this.$axios.post("/api/yunshi/common/theme").then((res)=> {
					if(res.code == 1) {
						let _data = res.data;
						let themeid = _data.id;
						this.setThemeid(themeid);
						this.setTemplate({
							qywx_template_filename: _data.qywx_template_filename || '',
							phone_template_filename: _data.phone_template_filename || '',
							paydoc_template_filename: _data.paydoc_template_filename || '',
							unpaydoc_template_filename: _data.unpaydoc_template_filename || ''
						});
	
						let phone_page = _data.phone_template_filename || 'submitPhoneNum';
						if(phone_page) this.getPayUrl$0(payWay, payType, phone_page, oid);
					};
				}).catch((err)=> {
					console.log(err);
				});
			};
		},
		getPayUrl$0(payWay, payType, payPage = "submitPhoneNum", oid) {
			let params = {
				oid,
				// backurl: location.origin + '/' + payPage + '?oid=' + oid,
				backurl: location.href,
				payWay,
				payType
			};

			let bd_vid = this.$route.query.bd_vid;
			let requestid = this.$route.query.requestid;
			let adid = this.$route.query.adid;

			if(bd_vid) {
				params.domain = location.origin;
				params.bd_vid = bd_vid;
				params.filename = this.$route.query.filename;
				params.themefunction_id = this.$route.query.themefunction_id;
			};

			if(requestid) {
				params.domain = location.origin;
				params.requestid = requestid;
				params.creativeId = adid;
				params.filename = this.$route.query.filename;
				params.themefunction_id = this.$route.query.themefunction_id;
			};

			this.$axios.post("/api/pay/pay/getpayurl", params).then((res)=> {
				if(res.code == 1) {
					let _data = res.data.data;
					let url = _data.data && _data.data.payUrl;
					let qrCodeUrl = _data.data && _data.data.qrCode;
					if(url && payType != "SCANPAY_WEIXIN") {
						window.location.href = url;
					}else if(qrCodeUrl && payType == "SCANPAY_WEIXIN") {
						this.showQrCode = true;
						this.$nextTick(()=> {
							this.getQrCode(qrCodeUrl);
						});
					};
				};
			}).catch((err)=> {
				console.log(err);
			});
		},
		getQrCode(text) { // 生成二维码
			this.$refs.qrCodeUrl.innerHTML = "";
            new QRCode(this.$refs.qrCodeUrl, {
                text: text, // 需要转换为二维码的内容
                width: 160,
                height: 160,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            });
		},

		swiperGo(routerName) {
			// 分离查询参数
			let [name, queryString] = routerName.split('?');
			let query = {};
		
			if (queryString) {
				// 将查询参数解析为对象
				queryString.split('&').forEach(param => {
					let [key, value] = param.split('=');
					query[key] = value;
				});
			}
		
			// 使用解析后的名称和查询参数对象进行路由跳转
			this.$router.push({
				name: name,
				query: query
			}).catch(err => {
				if (err.name !== 'NavigationDuplicated') {
					throw err;
				}
			});
		}
		
		
    },
	beforeDestroy() {
		if(this.timer) {
			clearTimeout(this.timer);
		}
	}
};  