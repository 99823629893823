<template>
    <!-- 轮盘 -->
    <div class="lunpan_box">
        <div class="lunpan">
            <img src="https://szwh.oss-cn-chengdu.aliyuncs.com/assets/imgs/baobaoqm/luopan.png?x-oss-process=style/webp" alt="轮盘">
            <img src="https://szwh.oss-cn-chengdu.aliyuncs.com/assets/imgs/baobaoqm/zhizheng.png?x-oss-process=style/webp" alt="轮盘">
        </div>
        <div class="lunpan_color"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.lunpan_color {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    opacity: .7;
    background-color: #000;
    transition: opacity .5s;
}

.lunpan {
    width: 170px;
    height: 170px;
    margin: 0 auto;
    position: fixed;
    top: 40%;
    left: 50%;
    z-index: 105;
    margin-left: -85px;
    vertical-align: middle;
}

.lunpan img:nth-of-type(1) {
    width: 170px;
    height: 170px;
    -webkit-animation: rotate 4s linear infinite;
    animation: rotate 4s linear infinite;
}

.lunpan img:nth-of-type(2) {
    width: 20px;
    height: 140px;
    position: fixed;
    top: 42%;
    left: 50%;
    margin: 0 auto;
    margin-left: -10px;
    animation: rotate2 4s linear infinite;
}

@keyframes rotate2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>