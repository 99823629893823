import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from './util/request'
Vue .prototype.$axios = axios;

import './style/base.css';
import './style/common.less';

import './components/rui-datepicker-1.2/rui-datepicker.min';
import './components/rui-datepicker-1.2/rui-datepicker.min.less'; 

import '@/components/chenrf/rui-datepicker.min.js'
import '@/components/chenrf/rui-datepicker.min.less'

import TopScroll from '@/components/top-scroll/index.vue'
Vue.component('TopScroll', TopScroll);

import Swiper from 'swiper';
// import 'swiper/css';
Vue.prototype.Swiper = Swiper;

import layer from "layui-layer";
window.layer = layer;

// import '@/components/lCalendar/lCalendar.js'
// import '@/components/lCalendar/lCalendar.less'

import '@/components/globalComp.js'

import globalMixin from '@/mixin/globalMixin';
Vue.mixin(globalMixin);

const isWechatBrowser = /MicroMessenger/i.test(navigator.userAgent); // 判断是否在微信浏览器环境
Vue.prototype.isWechat = isWechatBrowser;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
