"use strict";
window.ruiDatepicker = (function () {
  "classList" in document.documentElement ||
    Object.defineProperty(HTMLElement.prototype, "classList", {
      get: function () {
        function e(e) {
          return function (a) {
            var r = t.className.split(/\s+/g),
              n = r.indexOf(a);
            e(r, n, a), (t.className = r.join(" "));
          };
        }
        var t = this;
        return {
          add: e(function (e, t, a) {
            ~t || e.push(a);
          }),
          remove: e(function (e, t) {
            ~t && e.splice(t, 1);
          }),
          toggle: e(function (e, t, a) {
            ~t ? e.splice(t, 1) : e.push(a);
          }),
          contains: function (e) {
            return !!~t.className.split(/\s+/g).indexOf(e);
          },
          item: function (e) {
            return t.className.split(/\s+/g)[e] || null;
          },
        };
      },
    });
  var e = function () {
    this.gearDate,
      (this.minY = 1940),
      (this.minM = 1),
      (this.minD = 1),
      (this.maxY = 2030),
      (this.maxM = 12),
      (this.maxD = 31),
      (this.type = 1);
  };
  return (
    (e.prototype = {
      init: function (e) {
        (this.trigger = document.querySelector(e.id)),
          this.bindEvent("date"),
          (this.callback =
            this.trigger.getAttribute("data-callback") ||
            e.callback ||
            function () {});
      },
      bindEvent: function (e) {
        function t(e) {
          $(".gearDate").remove(); // 清除一下旧的 datepicker
          if (
            (document.activeElement.blur(),
            (w.gearDate = document.createElement("div")),
            (w.gearDate.className = "gearDate oneTwo"),
            w.trigger.getAttribute("data-toid-hour")
              ? (w.gearDate.innerHTML =
                  '<div class="date_ctrl bounceInUp"><div class="date_btn_wrap"><div class="date_btn lcalendar_cancel">取消</div><div class="date_btn lcalendar_finish">完成</div></div><div class="date_choice_wrap"><div class="date_class_box"><div class="date_class lcalendar_gongli">公历</div><div class="date_class lcalendar_nongli">农历</div></div><div class="date_roll_mask"><div class="date_roll date_roll_more"><div><div class="gear date_yy" data-datetype="date_yy"></div><div class="date_grid"></div></div><div><div class="gear date_mm" data-datetype="date_mm"></div><div class="date_grid"></div></div><div><div class="gear date_dd" data-datetype="date_dd"></div><div class="date_grid"></div></div><div><div class="gear date_hh" data-datetype="date_hh"></div><div class="date_grid"></div></div></div></div></div><div class="date_confirm_wrap"><div class="confirm_tit">请确认输入的时间是否正确</div><div class="confirm_p">公历：<b class="confirm_gongli"></b></div><div class="confirm_p">农历：<b class="confirm_nongli"></b></div></div></div>')
              : (w.gearDate.innerHTML =
                  '<div class="date_ctrl bounceInUp"><div class="date_btn_wrap"><div class="date_btn lcalendar_cancel">取消</div><div class="date_btn lcalendar_finish" data-isconfirm="0">完成</div></div><div class="date_choice_wrap"><div class="date_class_box"><div class="date_class lcalendar_gongli">公历</div><div class="date_class lcalendar_nongli">农历</div></div><div class="date_roll_mask"><div class="date_roll"><div><div class="gear date_yy" data-datetype="date_yy"></div><div class="date_grid"></div></div><div><div class="gear date_mm" data-datetype="date_mm"></div><div class="date_grid"></div></div><div><div class="gear date_dd" data-datetype="date_dd"></div><div class="date_grid"></div></div></div></div></div><div class="date_confirm_wrap"><div class="confirm_tit">请确认输入的时间是否正确</div><div class="confirm_p">公历：<b class="confirm_gongli"></b></div><div class="confirm_p">农历：<b class="confirm_nongli"></b></div></div></div>'),
            document.body.appendChild(w.gearDate),
            w.trigger.getAttribute("data-nochange"))
          ) {
            var t = w.gearDate.querySelector(".date_class");
            (t.style.zIndex = "-1"),
              (t.style.position = "relative"),
              (t.style.opacity = "0");
          }
          r();
          var n = "ontouchstart" in window;
          w.gearDate
            .querySelector(".lcalendar_cancel")
            .addEventListener(n ? "touchstart" : "click", p),
            w.gearDate
              .querySelector(".lcalendar_finish")
              .addEventListener(n ? "touchstart" : "click", h);
          var i = w.gearDate.querySelector(".lcalendar_gongli"),
            d = w.gearDate.querySelector(".lcalendar_nongli");
          i.addEventListener(
            n ? "touchstart" : "click",
            function () {
              a("gongli");
            },
            !1
          ),
            d.addEventListener(
              n ? "touchstart" : "click",
              function () {
                a("nongli");
              },
              !1
            );
          var s = w.gearDate.querySelector(".date_yy"),
            l = w.gearDate.querySelector(".date_mm"),
            o = w.gearDate.querySelector(".date_dd"),
            y = w.gearDate.querySelector(".date_hh");
          s.addEventListener("touchstart", v),
            l.addEventListener("touchstart", v),
            o.addEventListener("touchstart", v),
            y && y.addEventListener("touchstart", v),
            s.addEventListener("mousedown", m),
            l.addEventListener("mousedown", m),
            o.addEventListener("mousedown", m),
            y && y.addEventListener("mousedown", m),
            s.addEventListener("touchmove", u),
            l.addEventListener("touchmove", u),
            o.addEventListener("touchmove", u),
            y && y.addEventListener("touchmove", u),
            s.addEventListener("touchend", _),
            l.addEventListener("touchend", _),
            o.addEventListener("touchend", _),
            y && y.addEventListener("touchend", _),
            w.gearDate.addEventListener("touchmove", function (e) {
              e.preventDefault();
            }),
            navigator.userAgent.indexOf("Firefox") > 0
              ? (w.gearDate.addEventListener(
                  "DOMMouseScroll",
                  function (e) {
                    e.preventDefault();
                  },
                  !1
                ),
                s.addEventListener("DOMMouseScroll", c, !1),
                l.addEventListener("DOMMouseScroll", c, !1),
                o.addEventListener("DOMMouseScroll", c, !1),
                y && y.addEventListener("DOMMouseScroll", c, !1))
              : ((w.gearDate.onmousewheel = function (e) {
                  return !1;
                }),
                (s.onmousewheel = c),
                (l.onmousewheel = c),
                (o.onmousewheel = c),
                y && (y.onmousewheel = c));
        }
        function a(e) {
          var t = w.gearDate.querySelector(".lcalendar_nongli"),
            a = w.gearDate.querySelector(".lcalendar_gongli"),
            r = 0;
          if (
            ("nongli" == e && 1 != w.type
              ? ((t.className =
                  t.className
                    .replace(/active/, "")
                    .replace(/(^\s*)|(\s*$)/g, "") + " active"),
                (a.className = a.className.replace(/active/, "")),
                (w.type = 1),
                (r = 1))
              : "gongli" == e &&
                0 != w.type &&
                ((t.className = t.className.replace(/active/, "")),
                (a.className =
                  a.className
                    .replace(/active/, "")
                    .replace(/(^\s*)|(\s*$)/g, "") + " active"),
                (w.type = 0),
                (r = 1)),
            r)
          ) {
            var i = w.maxY - w.minY + 1,
              d = parseInt(
                Math.round(
                  w.gearDate.querySelector(".date_yy").getAttribute("val")
                )
              ),
              l =
                parseInt(
                  Math.round(
                    w.gearDate.querySelector(".date_mm").getAttribute("val")
                  )
                ) + 1,
              o =
                parseInt(
                  Math.round(
                    w.gearDate.querySelector(".date_dd").getAttribute("val")
                  )
                ) + 1,
              c = (d % i) + w.minY,
              e = w.type ? 0 : 1,
              m = b[d].Intercalation ? b[d].Intercalation : 0;
            !w.type &&
              m &&
              (m == l - 1 ? (l = -(l - 1)) : m < l - 1 ? (l -= 1) : (l = l));
            var v = s(e, c, l, o),
              u = b[v.yy - w.minY].Intercalation
                ? b[v.yy - w.minY].Intercalation
                : 0;
            u &&
              w.type &&
              (v.mm < 0 ? (v.mm = 1 - v.mm) : v.mm > u && (v.mm = v.mm + 1)),
              w.gearDate
                .querySelector(".date_yy")
                .setAttribute("val", v.yy - w.minY),
              w.gearDate
                .querySelector(".date_mm")
                .setAttribute("val", v.mm - 1),
              w.gearDate
                .querySelector(".date_dd")
                .setAttribute("val", v.dd - 1),
              w.gearDate.querySelector(".date_yy").setAttribute("top", ""),
              n();
          }
        }
        function r() {
          var e = new Date(),
            t = { yy: e.getYear(), mm: e.getMonth(), dd: e.getDate() - 1 };
          if (
            /^\d{4}-\d{1,2}-\d{1,2}$/.test(w.trigger.getAttribute("data-date"))
          ) {
            var a = w.trigger.getAttribute("data-date").match(/(^|-)\d{1,4}/g);
            (t.yy = a[0] - w.minY),
              (t.mm = a[1].replace(/-/g, "") - 1),
              (t.dd = a[2].replace(/-/g, "") - 1);
          } else (t.yy = t.yy + 1874 - w.minY), (t.mm = 6), (t.dd = 0);
          if (
            (w.gearDate.querySelector(".date_yy").setAttribute("val", t.yy),
            w.gearDate.querySelector(".date_mm").setAttribute("val", t.mm),
            w.gearDate.querySelector(".date_dd").setAttribute("val", t.dd),
            w.gearDate.querySelector(".date_hh"))
          ) {
            var r = 0;
            w.trigger.getAttribute("data-hour") &&
              w.trigger.getAttribute("data-hour") >= 0 &&
              (r = parseInt(
                Math.round(w.trigger.getAttribute("data-hour")) + 1
              )),
              w.gearDate.querySelector(".date_hh").setAttribute("val", r);
          }
          if (parseInt(w.trigger.getAttribute("data-type"))) {
            w.type = 1;
            var i = w.gearDate.querySelector(".lcalendar_nongli");
            i.className =
              i.className.replace(/active/, "").replace(/(^\s*)|(\s*$)/g, "") +
              " active";
            var d = w.maxY - w.minY + 1,
              l = (t.yy % d) + w.minY,
              o = t.mm + 1,
              c = t.dd + 1,
              m = s(0, l, o, c),
              v = b[m.yy - w.minY].Intercalation
                ? b[m.yy - w.minY].Intercalation
                : 0;
            m.mm < 0
              ? (m.mm = 1 - m.mm)
              : v && m.mm - 1 >= v && (m.mm = m.mm + 1),
              w.gearDate
                .querySelector(".date_yy")
                .setAttribute("val", m.yy - w.minY),
              w.gearDate
                .querySelector(".date_mm")
                .setAttribute("val", m.mm - 1),
              w.gearDate
                .querySelector(".date_dd")
                .setAttribute("val", m.dd - 1);
          } else {
            w.type = 0;
            var u = w.gearDate.querySelector(".lcalendar_gongli");
            u.className =
              u.className.replace(/active/, "").replace(/(^\s*)|(\s*$)/g, "") +
              " active";
          }
          n();
        }
        function n() {
          var e = w.maxY - w.minY + 1,
            t = w.gearDate.querySelector(".date_yy"),
            a = "";
          if (t && t.getAttribute("val")) {
            for (
              var r = parseInt(t.getAttribute("val")), n = 0;
              n <= e - 1;
              n++
            )
              a += "<div class='tooth'>" + (w.minY + n) + "</div>";
            t.innerHTML = a;
            var s = Math.floor(parseFloat(t.getAttribute("top")));
            if (isNaN(s))
              (t.style.transform = "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-webkit-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-moz-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-ms-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                (t.style["-o-transform"] =
                  "translate(0," + (8 - 2 * r) + "em)"),
                t.setAttribute("top", 8 - 2 * r + "em");
            else {
              s % 2 == 0 ? (s = s) : (s += 1), s > 8 && (s = 8);
              var l = 8 - 2 * (e - 1);
              s < l && (s = l),
                (t.style.transform = "translate(0," + s + "em)"),
                (t.style["-webkit-transform"] = "translate(0," + s + "em)"),
                (t.style["-moz-transform"] = "translate(0," + s + "em)"),
                (t.style["-ms-transform"] = "translate(0," + s + "em)"),
                (t.style["-o-transform"] = "translate(0," + s + "em)"),
                t.setAttribute("top", s + "em"),
                (r = Math.abs(s - 8) / 2),
                t.setAttribute("val", r);
            }
            var o = w.gearDate.querySelector(".date_mm");
            if (o && o.getAttribute("val")) {
              a = "";
              var c = parseInt(o.getAttribute("val")),
                m = b[r].Intercalation ? b[r].Intercalation : 0;
              if (m && w.type) var v = 12;
              else var v = 11;
              var u = 0;
              r == e - 1 && (v = (w.type, w.maxM - 1)),
                0 == r && (u = w.type ? w.minM - 1 : w.minM);
              for (var n = 0; n < v - u + 1; n++) {
                var _ = u + n + 1;
                w.type &&
                  (_ =
                    m && m == n
                      ? d("rm", _ - 1)
                      : m && m < n
                      ? d("mm", _ - 1)
                      : d("mm", _)),
                  (a += "<div class='tooth'>" + _ + "</div>");
              }
              (o.innerHTML = a),
                c > v
                  ? ((c = v), o.setAttribute("val", c))
                  : c < u && ((c = v), o.setAttribute("val", c)),
                (o.style.transform =
                  "translate(0," + (8 - 2 * (c - u)) + "em)"),
                (o.style["-webkit-transform"] =
                  "translate(0," + (8 - 2 * (c - u)) + "em)"),
                (o.style["-moz-transform"] =
                  "translate(0," + (8 - 2 * (c - u)) + "em)"),
                (o.style["-ms-transform"] =
                  "translate(0," + (8 - 2 * (c - u)) + "em)"),
                (o.style["-o-transform"] =
                  "translate(0," + (8 - 2 * (c - u)) + "em)"),
                o.setAttribute("top", 8 - 2 * (c - u) + "em");
              var y = w.gearDate.querySelector(".date_dd");
              if (y && y.getAttribute("val")) {
                a = "";
                var g = parseInt(y.getAttribute("val")),
                  p = i(r, c),
                  h = p - 1,
                  D = 0;
                r == e - 1 &&
                  12 == c + 1 &&
                  (h = w.type ? w.maxD - 15 : w.maxD - 1),
                  0 == r &&
                    2 == c + 1 &&
                    (D = w.type ? w.minD - 1 : w.minD + 6);
                for (var n = 0; n < h - D + 1; n++) {
                  var _ = w.type ? d("dd", D + n + 1) : D + n + 1;
                  a += "<div class='tooth'>" + _ + "</div>";
                }
                (y.innerHTML = a),
                  g > h
                    ? ((g = h), y.setAttribute("val", g))
                    : g < D && ((g = D), y.setAttribute("val", g)),
                  (y.style.transform =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (y.style["-webkit-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (y.style["-moz-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (y.style["-ms-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  (y.style["-o-transform"] =
                    "translate(0," + (8 - 2 * (g - D)) + "em)"),
                  y.setAttribute("top", 8 - 2 * (g - D) + "em");
                var A = w.gearDate.querySelector(".date_hh");
                if (A && A.getAttribute("val")) {
                  var M = parseInt(A.getAttribute("val"));
                  a = "<div class='tooth'>时辰未知</div>";
                  for (var n = 0; n < 24; n++) {
                    var _ = d("hh", n);
                    a += "<div class='tooth'>" + _ + "</div>";
                  }
                  (A.innerHTML = a),
                    (A.style.transform = "translate(0," + (8 - 2 * M) + "em)"),
                    (A.style["-webkit-transform"] =
                      "translate(0," + (8 - 2 * M) + "em)"),
                    (A.style["-moz-transform"] =
                      "translate(0," + (8 - 2 * M) + "em)"),
                    (A.style["-ms-transform"] =
                      "translate(0," + (8 - 2 * M) + "em)"),
                    (A.style["-o-transform"] =
                      "translate(0," + (8 - 2 * M) + "em)"),
                    A.setAttribute("top", 8 - 2 * M + "em");
                }
                f();
              }
            }
          }
        }
        function i(e, t) {
          return 1 == w.type
            ? b[e].MonthDays[t]
              ? 30
              : 29
            : 1 == t
            ? ((e += w.minY),
              (e % 4 == 0 && e % 100 != 0) || (e % 400 == 0 && e % 4e3 != 0)
                ? 29
                : 28)
            : 3 == t || 5 == t || 8 == t || 10 == t
            ? 30
            : 31;
        }
        function d(e, t) {
          var a = [
              "闰正月",
              "闰二月",
              "闰三月",
              "闰四月",
              "闰五月",
              "闰六月",
              "闰七月",
              "闰八月",
              "闰九月",
              "闰十月",
              "闰冬月",
              "闰腊月",
            ],
            r = [
              "正月",
              "二月",
              "三月",
              "四月",
              "五月",
              "六月",
              "七月",
              "八月",
              "九月",
              "十月",
              "十一月",
              "十二月",
            ],
            n = [
              "初一",
              "初二",
              "初三",
              "初四",
              "初五",
              "初六",
              "初七",
              "初八",
              "初九",
              "初十",
              "十一",
              "十二",
              "十三",
              "十四",
              "十五",
              "十六",
              "十七",
              "十八",
              "十九",
              "二十",
              "廿一",
              "廿二",
              "廿三",
              "廿四",
              "廿五",
              "廿六",
              "廿七",
              "廿八",
              "廿九",
              "三十",
              "三十一",
            ],
            i = [
              "00:00-00:59(早子)",
              "01:00-01:59(丑)",
              "02:00-02:59(丑)",
              "03:00-03:59(寅)",
              "04:00-04:59(寅)",
              "05:00-05:59(卯)",
              "06:00-06:59(卯)",
              "07:00-07:59(辰)",
              "08:00-08:59(辰)",
              "09:00-09:59(巳)",
              "10:00-10:59(巳)",
              "11:00-11:59(午)",
              "12:00-12:59(午)",
              "13:00-13:59(未)",
              "14:00-14:59(未)",
              "15:00-15:59(申)",
              "16:00-16:59(申)",
              "17:00-17:59(酉)",
              "18:00-18:59(酉)",
              "19:00-19:59(戌)",
              "20:00-20:59(戌)",
              "21:00-21:59(亥)",
              "22:00-22:59(亥)",
              "23:00-23:59(晚子)",
            ],
            d = [
              "子",
              "丑",
              "丑",
              "寅",
              "寅",
              "卯",
              "卯",
              "辰",
              "辰",
              "巳",
              "巳",
              "午",
              "午",
              "未",
              "未",
              "申",
              "申",
              "酉",
              "酉",
              "戌",
              "戌",
              "亥",
              "亥",
              "子",
            ];
          return "rm" == e
            ? a[t - 1]
            : "mm" == e
            ? r[t - 1]
            : "dd" == e
            ? n[t - 1]
            : "hh" == e
            ? i[t]
            : "hhcn" == e
            ? d[t]
            : void 0;
        }
        function s(e, t, a, r) {
          var n = t,
            i = a,
            d = r,
            s = [
              0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 365, 396,
              0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335, 366, 397,
            ];
          if (0 == e) {
            var o = parseInt(n),
              c = parseInt(i),
              m = parseInt(d),
              v = c - 1,
              u = l(o),
              _ = o - 1940,
              y = s[14 * u + v] + m,
              g = y + b[_].BaseKanChih,
              p = g % 60;
            if (
              ((p = p < 22 ? 22 - p : 82 - p),
              (p += 3),
              p < 10 && (p += 60),
              y <= b[_].BaseDays)
            ) {
              _--;
              var h = o - 1;
              (u = l(h)), (v += 12), (y = s[14 * u + v] + m);
            } else var h = o;
            var f = b[_].BaseDays;
            for (I = 0; I < 13; I++) {
              var w = f + b[_].MonthDays[I] + 29;
              if (y <= w) break;
              f = w;
            }
            var D = I + 1,
              A = y - f,
              M = b[_].Intercalation;
            return (
              0 != M && D > M && --D == M && (D = -M),
              D > 12 && (D -= 12),
              { yy: h, mm: D, dd: A }
            );
          }
          var h = parseInt(n),
            D = parseInt(i),
            A = parseInt(d),
            _ = h - 1940,
            M = b[_].Intercalation,
            S = D;
          0 != M && (S > M ? S++ : S == -M && (S = M + 1)), S--;
          for (var y = 0, I = 0; I < S; I++) y += b[_].MonthDays[I] + 29;
          y += b[_].BaseDays + A;
          for (var u = l(h), I = 13; I >= 0 && !(y > s[14 * u + I]); I--);
          var m = y - s[14 * u + I];
          if (I <= 11)
            var o = h,
              c = I + 1;
          else
            var o = h + 1,
              c = I - 11;
          return { yy: o, mm: c, dd: m };
        }
        function l(e) {
          return e % 400 == 0 ? 1 : e % 100 == 0 ? 0 : e % 4 == 0 ? 1 : 0;
        }
        function o(e, t, a, r, n, i, d, s, l, o, c, m, v, u, _, y, g) {
          (this.BaseDays = e),
            (this.Intercalation = t),
            (this.BaseWeekday = a),
            (this.BaseKanChih = r),
            (this.MonthDays = [n, i, d, s, l, o, c, m, v, u, _, y, g]);
        }
        function c(e) {
          var e = e || event,
            t = !0;
          t = e.wheelDelta ? e.wheelDelta > 0 : e.detail < 0;
          var a = t ? 21 : -21;
          e.preventDefault();
          for (var r = e.target; ; ) {
            if (r.classList.contains("gear")) break;
            r = r.parentElement;
          }
          clearInterval(r["int_" + r.id]),
            (r["old_" + r.id] = 0),
            (r["o_t_" + r.id] = new Date().getTime());
          var n = r.getAttribute("top");
          (r["o_d_" + r.id] = n ? parseFloat(n.replace(/em/g, "")) : 0),
            (r["new_" + r.id] = a),
            (r["n_t_" + r.id] = new Date().getTime() + 360);
          var i = (18 * (r["new_" + r.id] - r["old_" + r.id])) / 370;
          (r["pos_" + r.id] = r["o_d_" + r.id] + i),
            r.setAttribute("top", r["pos_" + r.id] + "em");
          var d =
            (r["new_" + r.id] - r["old_" + r.id]) /
            (r["n_t_" + r.id] - r["o_t_" + r.id]);
          return (
            Math.abs(d) <= 0.2
              ? (r["spd_" + r.id] = d < 0 ? -0.08 : 0.08)
              : Math.abs(d) <= 0.5
              ? (r["spd_" + r.id] = d < 0 ? -0.16 : 0.16)
              : (r["spd_" + r.id] = d / 2),
            r["pos_" + r.id] || (r["pos_" + r.id] = 0),
            e.preventDefault && e.preventDefault(),
            y(r),
            !1
          );
        }
        function m(e) {
          e.preventDefault();
          for (var t = e.target, a = t, r = !1; ; ) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          clearInterval(t["int_" + t.id]),
            (t["old_" + t.id] = e.screenY),
            (t["o_t_" + t.id] = new Date().getTime());
          var n = t.getAttribute("top");
          (t["o_d_" + t.id] = n ? parseFloat(n.replace(/em/g, "")) : 0),
            (document.onmousemove = function (e) {
              (r = !0), (e = e || window.event), e.preventDefault();
              for (var t = a; ; ) {
                if (!t) break;
                if (t.classList.contains("gear")) break;
                t = t.parentElement;
              }
              if (t) {
                (t["new_" + t.id] = e.screenY),
                  (t["n_t_" + t.id] = new Date().getTime());
                var n = (18 * (t["new_" + t.id] - t["old_" + t.id])) / 370;
                (t["pos_" + t.id] = t["o_d_" + t.id] + n),
                  (t.style.transform =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-webkit-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-moz-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-ms-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  (t.style["-o-transform"] =
                    "translate(0," + t["pos_" + t.id] + "em)"),
                  t.setAttribute("top", t["pos_" + t.id] + "em");
              }
            }),
            (document.onmouseup = function (e) {
              if (!r)
                return (
                  (document.onmousemove = null), (document.onmouseup = null), !1
                );
              (e = e || window.event), e.preventDefault();
              for (var t = a; ; ) {
                if (!t) break;
                if (t.classList.contains("gear")) break;
                t = t.parentElement;
              }
              if (t) {
                var n =
                  (t["new_" + t.id] - t["old_" + t.id]) /
                  (t["n_t_" + t.id] - t["o_t_" + t.id]);
                Math.abs(n) <= 0.2
                  ? (t["spd_" + t.id] = n < 0 ? -0.08 : 0.08)
                  : Math.abs(n) <= 0.5
                  ? (t["spd_" + t.id] = n < 0 ? -0.16 : 0.16)
                  : (t["spd_" + t.id] = n / 2),
                  t["pos_" + t.id] || (t["pos_" + t.id] = 0),
                  y(t),
                  (document.onmousemove = null),
                  (document.onmouseup = null);
              }
            });
        }
        function v(e) {
          e.preventDefault();
          var t = e.target;
          for (t.touchTip = !1; ; ) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          clearInterval(t["int_" + t.id]),
            (t["old_" + t.id] = e.targetTouches[0].screenY),
            (t["o_t_" + t.id] = new Date().getTime());
          var a = t.getAttribute("top");
          t["o_d_" + t.id] = a ? parseFloat(a.replace(/em/g, "")) : 0;
        }
        function u(e) {
          e.preventDefault();
          var t = e.target;
          for (t.touchTip = !0; ; ) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          (t["new_" + t.id] = e.targetTouches[0].screenY),
            (t["n_t_" + t.id] = new Date().getTime());
          var a = (18 * (t["new_" + t.id] - t["old_" + t.id])) / 370;
          (t["pos_" + t.id] = t["o_d_" + t.id] + a),
            (t.style.transform = "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-webkit-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-moz-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-ms-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            (t.style["-o-transform"] =
              "translate(0," + t["pos_" + t.id] + "em)"),
            t.setAttribute("top", t["pos_" + t.id] + "em");
        }
        function _(e) {
          e.preventDefault();
          var t = e.target;
          if (!t.touchTip) return !1;
          for (;;) {
            if (t.classList.contains("gear")) break;
            t = t.parentElement;
          }
          var a =
            (t["new_" + t.id] - t["old_" + t.id]) /
            (t["n_t_" + t.id] - t["o_t_" + t.id]);
          Math.abs(a) <= 0.2
            ? (t["spd_" + t.id] = a < 0 ? -0.08 : 0.08)
            : Math.abs(a) <= 0.5
            ? (t["spd_" + t.id] = a < 0 ? -0.16 : 0.16)
            : (t["spd_" + t.id] = a / 2),
            t["pos_" + t.id] || (t["pos_" + t.id] = 0),
            y(t);
        }
        function y(e) {
          var t = 0,
            a = !1,
            r = w.maxY - w.minY + 1;
          clearInterval(e["int_" + e.id]),
            (e["int_" + e.id] = setInterval(function () {
              var n = e["pos_" + e.id],
                d = e["spd_" + e.id] * Math.exp(-0.03 * t);
              if (((n += d), Math.abs(d) > 0.1));
              else {
                d = 0.1;
                var s = 2 * Math.round(n / 2);
                Math.abs(n - s) < 0.02 ? (a = !0) : n > s ? (n -= d) : (n += d);
              }
              switch (
                (n > 8 && ((n = 8), (a = !0)), e.getAttribute("data-datetype"))
              ) {
                case "date_yy":
                  var l = 8 - 2 * (r - 1);
                  if ((n < l && ((n = l), (a = !0)), a)) {
                    var o = Math.abs(n - 8) / 2;
                    g(e, o), clearInterval(e["int_" + e.id]);
                  }
                  break;
                case "date_mm":
                  var c = w.gearDate.querySelector(".date_yy"),
                    m = parseInt(c.getAttribute("val"));
                  if ((b[m].Intercalation ? b[m].Intercalation : 0) && w.type)
                    var v = 12;
                  else var v = 11;
                  var u = 0;
                  m == r - 1 && (v = (w.type, w.maxM - 1)),
                    0 == m && (u = w.type ? w.minM - 1 : w.minM);
                  var l = 8 - 2 * (v - u);
                  if ((n < l && ((n = l), (a = !0)), a)) {
                    var o = Math.abs(n - 8) / 2 + u;
                    g(e, o), clearInterval(e["int_" + e.id]);
                  }
                  break;
                case "date_dd":
                  var c = w.gearDate.querySelector(".date_yy"),
                    _ = w.gearDate.querySelector(".date_mm"),
                    m = parseInt(c.getAttribute("val")),
                    y = parseInt(_.getAttribute("val")),
                    p = i(m, y),
                    h = p - 1,
                    f = 0;
                  m == r - 1 &&
                    12 == y + 1 &&
                    (h = w.type ? w.maxD - 15 : w.maxD - 1),
                    0 == m &&
                      2 == y + 1 &&
                      (f = w.type ? w.minD - 1 : w.minD + 6);
                  var l = 8 - 2 * (h - f);
                  if ((n < l && ((n = l), (a = !0)), a)) {
                    var o = Math.abs(n - 8) / 2 + f;
                    g(e, o), clearInterval(e["int_" + e.id]);
                  }
                  break;
                case "date_hh":
                  var l = -40;
                  if ((n < l && ((n = l), (a = !0)), a)) {
                    var o = Math.abs(n - 8) / 2;
                    g(e, o), clearInterval(e["int_" + e.id]);
                  }
              }
              (e["pos_" + e.id] = n),
                (e.style.transform = "translate(0," + n + "em)"),
                (e.style["-webkit-transform"] = "translate(0," + n + "em)"),
                (e.style["-moz-transform"] = "translate(0," + n + "em)"),
                (e.style["-ms-transform"] = "translate(0," + n + "em)"),
                (e.style["-o-transform"] = "translate(0," + n + "em)"),
                e.setAttribute("top", n + "em"),
                t++;
            }, 6));
        }
        function g(e, t) {
          (t = Math.round(t)), e.setAttribute("val", t), n();
        }
        function p(e, t) {
          var a = w.gearDate.querySelector(".lcalendar_finish"),
            r = w.gearDate.querySelector(".lcalendar_cancel");
          if ((e.preventDefault(), a.getAttribute("data-isconfirm") - 0 && !t))
            return (
              f(),
              a.setAttribute("data-isconfirm", 0),
              (w.gearDate.querySelector(".date_choice_wrap").style.display =
                "block"),
              (w.gearDate.querySelector(".date_confirm_wrap").style.display =
                "none"),
              (a.innerHTML = "完成"),
              (r.innerHTML = "取消"),
              !1
            );
          if ("function" == typeof window.CustomEvent) {
            var n = new CustomEvent("input");
            w.trigger.dispatchEvent(n);
          }
          document.body.removeChild(w.gearDate);
        }
        function h(e) {
          var t = f(),
            a = w.gearDate.querySelector(".lcalendar_finish"),
            r = w.gearDate.querySelector(".lcalendar_cancel");
          if (
            !(a.getAttribute("data-isconfirm") - 0) &&
            "true" == w.trigger.getAttribute("data-confirm")
          ) {
            var n = w.gearDate.querySelector(".confirm_nongli"),
              i = w.gearDate.querySelector(".confirm_gongli");
            (w.gearDate.querySelector(".date_choice_wrap").style.display =
              "none"),
              (w.gearDate.querySelector(".date_confirm_wrap").style.display =
                "block"),
              a.setAttribute("data-isconfirm", 1),
              (a.innerHTML = "确认"),
              (r.innerHTML = "返回修改");
            var s = "",
              l = "";
            t.hh &&
              (t.hh < 0
                ? ((s = "时辰未知"), (l = "时辰未知"))
                : ((l = t.hh + "时"), (s = d("hhcn", t.hh) + "时"))),
              (i.innerHTML = t.yy + "年" + t.mm + "月" + t.dd + "日 " + l);
            var o = t._mm < 0 ? d("rm", -t._mm) : d("mm", t._mm);
            return (
              (n.innerHTML = t._yy + "年" + o + d("dd", t._dd) + " " + s), !1
            );
          }
          w.trigger.setAttribute("data-date", t.yy + "-" + t.mm + "-" + t.dd),
            w.trigger.setAttribute(
              "data-sdate",
              t._yy + "-" + t._mm + "-" + t._dd
            ),
            w.trigger.setAttribute("data-hour", t.hh),
            w.trigger.setAttribute("data-shour", t._hh);
          var c = w.trigger.getAttribute("data-toid-date"),
            m = w.trigger.getAttribute("data-toid-hour"),
            v = w.trigger.getAttribute("data-toid-sdate"),
            u = w.trigger.getAttribute("data-toid-shour");
          c &&
            (document.getElementById(c).value = t.yy + "-" + t.mm + "-" + t.dd),
            m && (document.getElementById(m).value = t.hh < 0 ? "" : t.hh),
            v &&
              (document.getElementById(v).value =
                t._yy + "-" + t._mm + "-" + t._dd),
            u && (document.getElementById(u).value = t._hh < 0 ? "" : t._hh);
          var _ = "";
          if (w.type) {
            var o = t._mm < 0 ? d("rm", -t._mm) : d("mm", t._mm);
            m && (_ = t.hh < 0 ? "时辰未知" : d("hhcn", t.hh) + "时"),
              (w.trigger.value =
                "农历:" + t._yy + "年" + o + d("dd", t._dd) + " " + _);
          } else
            m && (_ = t.hh < 0 ? "时辰未知" : t.hh + "时"),
              (w.trigger.value =
                "公历:" + t.yy + "-" + t.mm + "-" + t.dd + " " + _);
          w.callback({
            date1: t.yy + "/" + t.mm + "/" + t.dd,
            hour1: t.hh,
            date2:
              t._yy +
              "/" +
              (t._mm < 0 ? d("rm", -t._mm) : d("mm", t._mm)) +
              "/" +
              d("dd", t._dd),
            hour2: d("hhcn", t.hh) || -1,
            el: w.trigger,
            datepicker: w,
          }),
            p(e, "finish");
        }
        function f() {
          var e = w.maxY - w.minY + 1,
            t = parseInt(
              Math.round(
                w.gearDate.querySelector(".date_yy").getAttribute("val")
              )
            ),
            a = (t % e) + w.minY,
            r =
              parseInt(
                Math.round(
                  w.gearDate.querySelector(".date_mm").getAttribute("val")
                )
              ) + 1,
            n =
              parseInt(
                Math.round(
                  w.gearDate.querySelector(".date_dd").getAttribute("val")
                )
              ) + 1,
            i = w.gearDate.querySelector(".date_hh") ? 1 : 0;
          if (i)
            var l =
              parseInt(
                Math.round(
                  w.gearDate.querySelector(".date_hh").getAttribute("val")
                )
              ) - 1;
          var o = b[t].Intercalation ? b[t].Intercalation : 0;
          w.type &&
            o &&
            (o == r - 1 ? (r = -(r - 1)) : o < r - 1 ? (r -= 1) : (r = r));
          var c = s(w.type, a, r, n);
          if (w.type) {
            w.trigger.setAttribute("data-type", 1);
            r < 0 ? d("rm", -r) : d("mm", r);
            return (
              i && (l < 0 ? "时辰未知" : d("hh", l) + "时"),
              { yy: c.yy, mm: c.mm, dd: c.dd, _yy: a, _mm: r, _dd: n, hh: l }
            );
          }
          w.trigger.setAttribute("data-type", 0);
          return (
            i && (l < 0 ? "时辰未知" : l + "时"),
            { _yy: c.yy, _mm: c.mm, _dd: c.dd, yy: a, mm: r, dd: n, hh: l }
          );
        }
        var w = this,
          b = [
            new o(38, 0, 0, 38, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new o(26, 6, 2, 44, 1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new o(45, 0, 3, 49, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0),
            new o(35, 0, 4, 54, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new o(24, 4, 5, 59, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1),
            new o(43, 0, 0, 5, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1, 1),
            new o(32, 0, 1, 10, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1),
            new o(21, 2, 2, 15, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new o(40, 0, 3, 20, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new o(28, 7, 5, 26, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new o(47, 0, 6, 31, 0, 1, 1, 0, 1, 1, 0, 0, 1, 0, 1, 0, 1),
            new o(36, 0, 0, 36, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0),
            new o(26, 5, 1, 41, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1),
            new o(44, 0, 3, 47, 0, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1),
            new o(33, 0, 4, 52, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0),
            new o(23, 3, 5, 57, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1),
            new o(42, 0, 6, 2, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1),
            new o(30, 8, 1, 8, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0),
            new o(48, 0, 2, 13, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0),
            new o(38, 0, 3, 18, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new o(27, 6, 4, 23, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0),
            new o(45, 0, 6, 29, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0),
            new o(35, 0, 0, 34, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new o(24, 4, 1, 39, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new o(43, 0, 2, 44, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new o(32, 0, 4, 50, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1),
            new o(20, 3, 5, 55, 1, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0),
            new o(39, 0, 6, 0, 1, 1, 0, 1, 1, 0, 0, 1, 0, 1, 0, 1, 0),
            new o(29, 7, 0, 5, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new o(47, 0, 2, 11, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new o(36, 0, 3, 16, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0),
            new o(26, 5, 4, 21, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0, 1),
            new o(45, 0, 5, 26, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1),
            new o(33, 0, 0, 32, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1, 1),
            new o(22, 4, 1, 37, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1),
            new o(41, 0, 2, 42, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1),
            new o(30, 8, 3, 47, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1),
            new o(48, 0, 5, 53, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1),
            new o(37, 0, 6, 58, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new o(27, 6, 0, 3, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0),
            new o(46, 0, 1, 8, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0),
            new o(35, 0, 3, 14, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1, 1, 1),
            new o(24, 4, 4, 19, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1),
            new o(43, 0, 5, 24, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1),
            new o(32, 10, 6, 29, 1, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1),
            new o(50, 0, 1, 35, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new o(39, 0, 2, 40, 0, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1),
            new o(28, 6, 3, 45, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1, 0, 0),
            new o(47, 0, 4, 50, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new o(36, 0, 6, 56, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new o(26, 5, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 1),
            new o(45, 0, 1, 6, 0, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0),
            new o(34, 0, 2, 11, 0, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0),
            new o(22, 3, 4, 17, 0, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new o(40, 0, 5, 22, 1, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new o(30, 8, 6, 27, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1, 0, 1),
            new o(49, 0, 0, 32, 0, 1, 0, 1, 1, 0, 1, 0, 1, 1, 0, 0, 1),
            new o(37, 0, 2, 38, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new o(27, 5, 3, 43, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1, 1, 0, 1),
            new o(46, 0, 4, 48, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1),
            new o(35, 0, 5, 53, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1),
            new o(23, 4, 0, 59, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new o(42, 0, 1, 4, 1, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new o(31, 0, 2, 9, 1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0),
            new o(21, 2, 3, 14, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new o(39, 0, 5, 20, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1),
            new o(28, 7, 6, 25, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 1),
            new o(48, 0, 0, 30, 0, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1, 1),
            new o(37, 0, 1, 35, 1, 0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1),
            new o(25, 5, 3, 41, 1, 1, 0, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new o(44, 0, 4, 46, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1),
            new o(33, 0, 5, 51, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new o(22, 4, 6, 56, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0),
            new o(40, 0, 1, 2, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0),
            new o(30, 9, 2, 7, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1),
            new o(49, 0, 3, 12, 0, 1, 0, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1),
            new o(38, 0, 4, 17, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0),
            new o(27, 6, 6, 23, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1),
            new o(46, 0, 0, 28, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 0),
            new o(35, 0, 1, 33, 1, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0),
            new o(24, 4, 2, 38, 0, 1, 1, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1),
            new o(42, 0, 4, 44, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1),
            new o(31, 0, 5, 49, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0),
            new o(21, 2, 6, 54, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1),
            new o(40, 0, 0, 59, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1, 0, 1),
            new o(28, 6, 2, 5, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0),
            new o(47, 0, 3, 10, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 1),
            new o(36, 0, 4, 15, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0, 1),
            new o(25, 5, 5, 20, 1, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1, 1, 0),
            new o(43, 0, 0, 26, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1),
            new o(32, 0, 1, 31, 1, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1, 0, 0),
          ],
          D = !1,
          A = 0;
        "ontouchstart" in window
          ? (w.trigger.addEventListener("touchstart", function (e) {
              A = Date.now();
            }),
            w.trigger.addEventListener("touchmove", function (e) {
              D = !0;
            }),
            w.trigger.addEventListener("touchend", function (e) {
              !D && Date.now() - A < 150 && (e.preventDefault(), t()),
                (D = !1),
                (A = 0);
            }))
          : w.trigger.addEventListener("click", function () {
              t();
            });
      },
    }),
    e
  );
})();
