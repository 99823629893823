<template>
    <div class="protocol_pop_box" id="protocolPopBox">
        <div class="ppb_content">
            <ul class="ppb_tab_title J_tabTitle">
                <li class="active">隐私协议</li>
                <li>服务协议</li>
            </ul>
            <ul class="ppb_tab_text J_tabText">
                <li class="active" v-html="privacy_agreement"></li>
                <li v-html="service_agreement"></li>
            </ul>
            <div class="ppb_close J_close"><b>关闭</b></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            service_agreement: '', // 协议
			privacy_agreement: ''
        };
    },
    mounted() {
        this.getAgreement();
        this.initEvent();
    },
    methods: {
		getAgreement() { // 获取协议
			function def(val) {
				return val != undefined && val != null;
			};

			let privacy_agreement = localStorage.getItem('privacy_agreement');
			let service_agreement = localStorage.getItem('service_agreement');
			if(def(privacy_agreement) && def(service_agreement)) { // 缓存中获取协议
				this.privacy_agreement = privacy_agreement;
				this.service_agreement = service_agreement;
			}else { // 接口获取协议
				this.$axios.post("/api/yunshi/common/theme").then((res)=> {
					if(res.code == 1) {
                        this.privacy_agreement = _data.privacy_agreement;
				        this.service_agreement = _data.service_agreement;
						localStorage.setItem('privacy_agreement', _data.privacy_agreement);
						localStorage.setItem('service_agreement', _data.service_agreement);
					};
				}).catch((err)=> {
					console.log(err);
				});
			};
        },
        initEvent() {
            var e = $("#protocolPopBox");
            if (e.length) {
                var t = e.find(".J_tabTitle").children("li"),
                    a = e.find(".J_tabText").children("li"),
                    o = 0;
                $(".J_protocolShowBtn").on("click", function() {
                    e.show(), o = $(document).scrollTop(), $("body").css({
                        position: "fixed",
                        top: -o
                    })
                }), e.find(".J_close").on("click", function() {
                    $("body").css({
                        position: "static"
                    }), $(document).scrollTop(o), e.hide()
                }), t.on("click", function() {
                    $(this).addClass("active").siblings().removeClass("active"), a.eq($(this).index()).addClass("active").siblings().removeClass("active")
                })
            };
        }
    }
}
</script>

<style lang="less" scoped>
.protocol_pop_box {
    background: rgba(0, 0, 0, .7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none
}

.ppb_content {
    width: 88%;
    height: 80%;
    position: relative;
    padding: .4rem 0;
    top: 10%;
    left: 50%;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    max-width: 564px
}

.ppb_tab_title {
    background-color: #eee;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: .4rem
}

.ppb_tab_title li {
    width: 50%;
    float: left;
    text-align: center;
    line-height: .2rem;
    padding: .1rem 0;
    border-bottom: 1px solid #e6e6e6;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.ppb_tab_title li:last-child {
    border-left: 1px solid #e6e6e6
}

.ppb_tab_title .active {
    background-color: #fff;
    border-bottom: 1px solid #fff
}

.ppb_tab_text {
    height: 100%;
    line-height: 1.8;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: .06rem .14rem .1rem
}

.ppb_tab_text li {
    height: 100%;
    overflow-y: scroll;
    display: none
}

.ppb_tab_text .active {
    display: block
}

.ppb_tab_text h2 {
    margin-top: .16rem;
    font-size: 1.2em;
    font-weight: 600
}

.ppb_tab_text h3 {
    margin-top: .04rem;
    font-size: 1.1em
}

.ppb_tab_text p {
    margin-top: .1rem;
    word-break: break-word
}

.ppb_tab_text em {
    text-decoration: underline
}

.ppb_close {
    position: absolute;
    width: 100%;
    height: .4rem;
    left: 0;
    line-height: .4rem;
    text-align: center;
    bottom: 0
}

.ppb_close b {
    display: block;
    width: .5rem;
    height: .24rem;
    margin: .08rem auto 0;
    border: 1px solid #ccc;
    line-height: .24rem;
    -webkit-border-radius: .05rem;
    -moz-border-radius: .05rem;
    border-radius: .05rem
}
</style>