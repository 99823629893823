<template>
    <div class="home">
        <TopScroll
            class="home_ts"
            text="本网页数据安全由阿里云提供，严格按照法律法规和用户协议对您的信息进行全方位保护，请放心使用，测试仅供娱乐！"
        />
        <Banner :swiperData="bannerData" />
        <AllItems :showData="allItemData" />
        <OftenItems :showData="oftenItems1" />
        <OftenItems :showData="oftenItems2" />
        <p
            style="
                text-align: center;
                color: #999;
                padding: 20px 0;
                font-size: 14px;
            "
        >
            —— 已经到底啦 ——
        </p>
        <Footer bg="#FFF" bgColor="#dc8732" />
    </div>
</template>
  
  <script>
import QuickJump from "@/components/quick-jump/index.vue";

import Banner from "./comp/banner.vue";
import Footer from "@/components/footer/index.vue";
import AllItems from "./comp/allItems.vue";
import OftenItems from "./comp/oftenItems.vue";

export default {
    name: "HomeView",
    data() {
        return {
            oftenItems1: {},
            oftenItems2: {},
            allItemData: [],
            bannerData: [],
        };
    },
    components: {
        QuickJump, Banner, Footer, AllItems, OftenItems,
    },
    beforeCreate() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color: #fdfaf4;");
    },
    methods: {
        addMediaStyles() {
            const style = document.createElement("style");
            style.type = "text/css";
            style.id = "dynamic-media-styles";
            style.innerHTML = `
                  html {
                      max-width: 640px;
                      margin: 0 auto;
                      font-size: 100px;
                  }
              `;
            document.head.appendChild(style);
        },
        removeMediaStyles() {
            const style = document.getElementById("dynamic-media-styles");
            if (style) {
                document.head.removeChild(style);
            }
        },
        getThemeData() {
            let themeid = localStorage.getItem("themeid");
            let template = localStorage.getItem("template"); // error
            if (template) template = JSON.parse(template);
            this.$axios
                .post("/api/yunshi/common/theme")
                .then((res) => {
                    if (res.code == 1) {
                        let _data = res.data;
                        document.title = _data.title || "运势"; // 设置页面标题
                        this.bannerData = _data.banners_list || [];
                        this.allItemData =
                            (_data.list[0] && _data.list[0].list) || [];
                        this.oftenItems1 = _data.list[1] || {};
                        this.oftenItems2 = _data.list[2] || {};
                        console.log(this.allItemData, "8888");
                        if (!themeid) themeid = _data.id;
                        this.setThemeid(themeid);
                        this.setTemplate({
                            qywx_template_filename:
                                _data.qywx_template_filename || "",
                            phone_template_filename:
                                _data.phone_template_filename || "",
                            paydoc_template_filename:
                                _data.paydoc_template_filename || "",
                            unpaydoc_template_filename:
                                _data.unpaydosc_template_filename || "",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        setTemplate(template) {
            this.$store.commit("setTemplate", template);
        },
        setThemeid(themeid) {
            // set themeid
            this.$store.commit("setThemeid", themeid);
        },
    },
    mounted() {
        this.addMediaStyles();
        this.getThemeData();
    },
    beforeDestroy() {
        this.removeMediaStyles();
    },
};
</script>
  
  <style lang="less" scoped>
/deep/.home_ts.m_top_tip span {
    height: 0.25rem;
    line-height: 0.25rem;
    font-size: 0.12rem;
}
</style>
  