<template>
	<component :is="currentComponent"></component>
</template>

<script>
import TopScroll from "@/components/top-scroll/index.vue";
import QuickJump from "@/components/quick-jump/index.vue";

import Banner from "./comp/banner.vue";
import Footer from "./comp/footer.vue";
import AllItems from "./comp/allItems.vue";
import OftenItems from "./comp/oftenItems.vue";

export default {
	name: "HomeView",
	data() {
		return {
			oftenItems1: [
				{
				url: require("@/assets/imgs/home/5cd4d340222a5.png"),
				title: "测终生运",
				description: "看透你一生运势",
				router: "/?ac=bazijp",
				},
				{
				url: require("@/assets/imgs/home/15cd4d3c278fcf.png"),
				title: "事业财运",
				description: "八字测事业财运",
				router: "/?ac=bazi",
				},
				{
				url: require("@/assets/imgs/home/5f9fa3a7d6454.png"),
				title: "龙年运程",
				description: "犯太岁必测",
				router: "/?ac=jinnian",
				},
				{
				url: require("@/assets/imgs/home/653b902ad7c31.png"),
				title: "生肖运势",
				description: "生肖运势",
				router: "/?ac=shengxiao",
				},
			],
			oftenItems2: [
				{
				url: require("@/assets/imgs/home/5cd4d3565cfb0.png"),
				title: "脱单必测",
				description: "预知今年脱单指数",
				router: "/?ac=yinyuancs",
				},
				{
				url: require("@/assets/imgs/home/5cd4d3fb56705.png"),
				title: "算姻缘",
				description: "想脱单的你必看",
				router: "/?ac=bzyy",
				},
				{
				url: require("@/assets/imgs/home/5cd4d3b226ce1.png"),
				title: "婚前合婚",
				description: "避免错误的婚姻",
				router: "/?ac=hehun",
				},
				{
				url: require("@/assets/imgs/home/5cd4d414cc69e.png"),
				title: "宝宝起名",
				description: "宝宝起名",
				router: "/baobaoqm",
				},
			],
			bannerData: [],
			currentComponent: null
		};
	},
	components: {
		TopScroll,
		QuickJump,
		Banner,
		Footer,
		AllItems,
		OftenItems,
	},
	beforeCreate() {
		document.querySelector("body").setAttribute("style", "background-color: #fdfaf4;");
	},
	methods: {
		addMediaStyles() {
			const style = document.createElement("style");
			style.type = "text/css";
			style.id = "dynamic-media-styles";
			style.innerHTML = `
				html {
					max-width: 640px;
					margin: 0 auto;
					font-size: 100px;
				}
			`;
			document.head.appendChild(style);
		},
		removeMediaStyles() {
			const style = document.getElementById("dynamic-media-styles");
			if (style) {
				document.head.removeChild(style);
			}
		},
		getThemeData() {
			let themeid = localStorage.getItem('themeid');
			this.$axios.post("/api/yunshi/common/theme").then((res) => {
				if(res.code == 1) {
					let _data = res.data;
					this.bannerData = _data.banners_list || [];
					if(!themeid) themeid = _data.id;
					this.setThemeid(themeid);
					this.currentComponent = 'home'; // 首页主题切换
				};
			}).catch((err) => {
				console.log(err);
			});
		},
		setThemeid(themeid) { // set themeid
			this.$store.commit("setThemeid", themeid);
		}
	},
	mounted() {
		this.addMediaStyles();
		this.getThemeData();
	},
	beforeDestroy() {
		this.removeMediaStyles();
	}
};
</script>

<style lang="less" scoped>
/deep/.home_ts.m_top_tip span {
  height: 0.25rem;
  line-height: 0.25rem;
  font-size: 0.12rem;
}
</style>
