import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/index.vue";
import axios from '@/util/request'

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
	},
	{
		path: "/shengxiaoys", // 生肖运势
		name: "shengxiaoys",
		component: () => import("../views/Shengxiaoys/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/shengxiaoys_order", // 生肖运势 - 订单
		name: "shengxiaoys_order",
		component: () => import("../views/Shengxiaoys/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
		
	},
	{
		path: "/shengxiaoys_result", // 生肖运势 - 结果
		name: "shengxiaoys_result",
		component: () => import("../views/Shengxiaoys/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/bazijp", // 八字精批
		name: "bazijp",
		component: () => import("../views/Bazijp/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/bazijp_order", // 八字精批 - 订单
		name: "bazijp_order",
		component: () => import("../views/Bazijp/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/bazijp_result", // 八字精批 - 结果
		name: "bazijp_result",
		component: () => import("../views/Bazijp/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/jinnianys", // 流年运势
		name: "jinnianys",
		component: () => import("../views/Jinnianys/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/jinnianys_order", // 流年运势 - 订单
		name: "jinnianys_order",
		component: () => import("../views/Jinnianys/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/jinnianys_result", // 流年运势 - 结果
		name: "jinnianys_result",
		component: () => import("../views/Jinnianys/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/shenchenxp", // 生辰详批
		name: "shenchenxp",
		component: () => import("../views/Shenchenxp/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/shenchenxp_order", // 生辰详批 - 订单
		name: "shenchenxp_order",
		component: () => import("../views/Shenchenxp/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/shenchenxp_result", // 生辰详批 - 结果
		name: "shenchenxp_result",
		component: () => import("../views/Shenchenxp/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/bazihh", // 八字合婚
		name: "bazihh",
		component: () => import("../views/Bazihh/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/bazihh_order", // 八字合婚 - 订单
		name: "bazihh_order",
		component: () => import("../views/Bazihh/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/bazihh_result", // 八字合婚 - 结果
		name: "bazihh_result",
		component: () => import("../views/Bazihh/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/bazihh_a", // 八字合婚主题 a
		name: "bazihh_a",
		component: () => import("../views/Bazihh_a/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/bazihh_a_order", // 八字合婚主题a - 订单
		name: "bazihh_a_order",
		component: () => import("../views/Bazihh_a/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/yinyuanfx", // 姻缘分析
		name: "yinyuanfx",
		component: () => import("../views/Yinyuanfx/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/yinyuanfx_order", // 姻缘分析 - 订单
		name: "yinyuanfx_order",
		component: () => import("../views/Yinyuanfx/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/yinyuanfx_result", // 姻缘分析 - 结果
		name: "yinyuanfx_result",
		component: () => import("../views/Yinyuanfx/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/xingmingpd", // 姓名配对
		name: "xingmingpd",
		component: () => import("../views/Xingmingpd/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/xingmingpd_order", // 姓名配对 - 订单
		name: "xingmingpd_order",
		component: () => import("../views/Xingmingpd/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/xingmingpd_result", // 姓名配对 - 结果
		name: "xingmingpd_result",
		component: () => import("../views/Xingmingpd/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/caiyunfx", // 财运分析
		name: "caiyunfx",
		component: () => import("../views/Caiyunfx/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/caiyunfx_order", // 财运分析 - 订单
		name: "caiyunfx_order",
		component: () => import("../views/Caiyunfx/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/caiyunfx_result", // 财运分析 - 结果
		name: "caiyunfx_result",
		component: () => import("../views/Caiyunfx/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/haomajx", // 号码吉凶
		name: "haomajx",
		component: () => import("../views/Haomajx/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/haomajx_order", // 号码吉凶 - 订单
		name: "haomajx_order",
		component: () => import("../views/Haomajx/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/haomajx_result", // 号码吉凶 - 结果
		name: "haomajx_result",
		component: () => import("../views/Haomajx/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/ziweids", // 紫微斗术
		name: "ziweids",
		component: () => import("../views/Ziweids/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/ziweids_order", // 紫微斗术 - 订单
		name: "ziweids_order",
		component: () => import("../views/Ziweids/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/ziweids_result", // 紫微斗术 - 结果
		name: "ziweids_result",
		component: () => import("../views/Ziweids/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/xingmingxp", // 姓名详批
		name: "xingmingxp",
		component: () => import("../views/Xingmingxp/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/xingmingxp_order", // 姓名详批 - 订单
		name: "xingmingxp_order",
		component: () => import("../views/Xingmingxp/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/xingmingxp_result", // 姓名详批 - 结果
		name: "xingmingxp_result",
		component: () => import("../views/Xingmingxp/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/baobaoqm", // 宝宝取名
		name: "baobaoqm",
		component: () => import("../views/Baobaoqm/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/baobaoqm_order", // 宝宝取名 - 订单
		name: "baobaoqm_order",
		component: () => import("../views/Baobaoqm/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/baobaoqm_result", // 宝宝取名 - 结果
		name: "baobaoqm_result",
		component: () => import("../views/Baobaoqm/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/suanyy", // 算姻缘
		name: "suanyy",
		component: () => import("../views/Suanyy/index.vue"),
		meta: {
			functionPage: true
		}
	},
	{
		path: "/suanyy_order", // 算姻缘 - 订单
		name: "suanyy_order",
		component: () => import("../views/Suanyy/order.vue"),
		meta: {
			pageTitle: "待支付订单"
		}
	},
	{
		path: "/suanyy_result", // 算姻缘 - 结果
		name: "suanyy_result",
		component: () => import("../views/Suanyy/result.vue"),
		meta: {
			resultPage: true
		}
	},
	{
		path: "/history", // 单号查询
		name: "history",
		component: () => import("../views/History/index.vue"),
		meta: {
			pageTitle: "订单查询"
		}
	},
	{
		path: "/coWechat", // 企业微信好友落地页
		name: "coWechat",
		component: () => import("../views/conversation/coWechat.vue"),
		meta: {
			pageTitle: "添加大师"
		}
	},
	{
		path: "/submitPhoneNum", // 手机号提交
		name: "submitPhoneNum",
		component: () => import("../views/conversation/submitPhoneNum.vue"),
		meta: {
			pageTitle: "手机号填写"
		}
	},
	{
		path: "/submitSuccess", // 提交成功
		name: "submitSuccess",
		component: () => import("../views/conversation/submitSuccess.vue"),
		meta: {
			pageTitle: "跳转中..."
		}
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

function parseQueryParams(url) {
	const query = url.split("?")[1];
	if (!query) {
		return {};
	}

	return query.split("&").reduce((params, param) => {
		const [key, value] = param.split("=");
		params[key] = decodeURIComponent(value.replace(/\+/g, " "));
		return params;
	}, {});
};

router.beforeEach((to, from, next) => {
	if(to.meta && to.meta.pageTitle) { // 页面 title 设置
		document.title = to.meta.pageTitle;
		next();
	}else if((to.meta && to.meta.resultPage) && (to.query && to.query.oid)) {
		let oid = to.query.oid;
		axios.post("/api/yunshi/common/searchDetail", { oid }).then((res) => {
			if(res.code == 1) {
				let _data = res.data;
				document.title = (_data.themefunctiontion + "结果页") || "结果页"; // 设置页面标题
			};

		}).catch((err) => {
			console.log(err);
		}).finally(()=> {
			next();
		});
	}else if((to.meta && to.meta.functionPage) && (to.query && to.query.themefunction_id)) {
		let id = to.query.themefunction_id;
		axios.post("/api/yunshi/common/detail", { id }).then((res) => {
			if(res.code == 1) {
				let _data = res.data;
				document.title = _data.title || "八字精批_姓名详批_八字合婚_专业测算平台"; // 设置页面标题
			};
		}).catch((err) => {
			console.log(err);
		}).finally(()=> {
			next();
		});
	}else {
		next();
	};
});

export default router;
