import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    themeid: "",
    pay_uid: "",
	  template: ""
  },
  getters: {},
  mutations: {
    setTemplate(state, template) {
        state.template = template;
        localStorage.setItem('setTemplate', JSON.stringify(template));
    },
    setThemeid(state, themeid) {
        state.themeid = themeid;
        localStorage.setItem('themeid', themeid);
    },
    setPayUid(state, pay_uid) {
        state.pay_uid = pay_uid;
        localStorage.setItem('pay_uid', pay_uid);
    },
  },
  actions: {},
  modules: {},
});
