<template>
    <div class="m_top_tip">
        <span>{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="less">
.m_top_tip {
    color: #fff;
    padding: 0 0.1rem;
    overflow: hidden;
    background: #6f6f6fc4;
}
.m_top_tip span {
    white-space: nowrap;
    display: inline-block;
    animation: marquee 18s linear infinite;
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.3rem;
}

@keyframes marquee {
    0% {
        transform: translateX(3.75rem);
    }
    100% {
        transform: translateX(-100%);
    }
}
</style>