<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
export default {
  	mounted() {
		document.title = "八字精批_姓名详批_八字合婚_专业测算平台";
		this.getPayUid();
		this.getThemeData();
	},
	methods: {
		getPayUid() {
			let pay_uid = localStorage.getItem('pay_uid');
			if(pay_uid) {
				this.setPayUid(pay_uid);
			}else {
				this.$axios.post("/api/yunshi/common/get_userid").then((res) => {
					if(res.code == 1) {
						let _data = res.data;
						this.setPayUid(_data.userid);
					};
				}).catch((err) => {
					console.log(err, 'get pay_uid');
				});
			};
		},
		setPayUid(pay_uid) { // set pay_uid
			this.$store.commit("setPayUid", pay_uid);
		},
		getThemeData() { // set themeid && set template
			let themeid = localStorage.getItem('themeid');
			let template = localStorage.getItem('template'); // error
			if(template) template = JSON.parse(template);
			this.$axios.post("/api/yunshi/common/theme").then((res)=> {
				if(res.code == 1) {
					let _data = res.data;
					if(!themeid) themeid = _data.id;
					this.setThemeid(themeid);
					this.setTemplate({
						qywx_template_filename: _data.qywx_template_filename || '',
						phone_template_filename: _data.phone_template_filename || '',
						paydoc_template_filename: _data.paydoc_template_filename || '',
						unpaydoc_template_filename: _data.unpaydoc_template_filename || ''
					});
					localStorage.setItem('privacy_agreement', _data.privacy_agreement);
					localStorage.setItem('service_agreement', _data.service_agreement);
				};
			}).catch((err)=> {
				console.log(err);
			});
          },
          setTemplate(template) { // set template
            	this.$store.commit("setTemplate", template);
          },
          setThemeid(themeid) { // set themeid
              this.$store.commit("setThemeid", themeid);
          }
	}
};
</script>
<style lang="less"></style>
