<template>
     <div class="public_pay_popup" id="publicPayPopup">
        <div class="public_pp_box">
            <div class="public_pp_close" id="publicPPClose">X</div>
            <div class="public_pp_tit">解锁查看所有测试结果</div>
            <div class="public_pp_price">
                <span>统一鉴定价：</span><strong>￥{{ allData.money }}元</strong>
            </div>
            <div class="public_pay_box">
            <template v-if="isMobile">
                <a class="pay_wx" data-method="Wechatpay3" target="_blank" @click="getPayUrl('WEIXIN', 'H5_WEIXIN')">微信支付</a>
                <a class="pay_zfb" data-method="Alipay2" target="_blank" @click="getPayUrl('ALIPAY', 'ALIPAY_H5')">支付宝</a>
            </template>
            <template v-else>
                <div class="adress-qr-adress" v-show="showQrCode">
                    <div ref="qrCodeUrl" style="width: 160px;height: 160px;border: 1px solid #eee;"></div>
                    <div style="margin-top: 12px;">请扫描二维码进行微信支付 ~</div>
                </div>
                <a class="pay_wx" data-method="Wechatpay3" target="_blank" @click="getPayUrl('WEIXIN', 'SCANPAY_WEIXIN')">微信支付</a>
            </template>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
		return {

		}
	},
	props: {
        allData: {
			default: ()=> ({})
		}
	},
};
</script>

<style lang="less" scoped>

</style>