import axios from "axios";

// Axios 实例
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    headers: {
        "Content-Type": "application/json;charset=utf-8"
    }
});

instance.interceptors.request.use( // 请求拦截器
    (config) => {
        // const token = localStorage.getItem("token"); // token
        // if (token) {
        //     config.headers.token = token;
        // };

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use( // 响应拦截器
    (res) => {
        // return response.data;
        return res.data;
    },
    (error) => {
        // if(error.response && error.response.status == 401) {
        //     store.commit("removeToken");
        // };
        return Promise.reject(error);
    }
);

export default instance;
