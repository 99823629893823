<template>
    <a class="m-order-history" target="_blank" @click="routerGo('/history')">订单查询</a>
</template>

<script>
export default {
    methods: {
        routerGo(path) {
            this.$router.push(path);
        }
    }
}
</script>

<style lang="less" scoped>
// in base.css
</style>